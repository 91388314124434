import React, { useState } from 'react';
import { IUserInfo } from '../../../types/main';

import "./keypad.scss";

interface KeypadProps {
  onLogin: (userInfo: IUserInfo) => void;//(code: string) => void; // Function to handle login after entering 4 digits
}


  
const baseLink = 'https://exec.artificial.opalstacked.com';
const logInLink2 = `${baseLink}/api/login2/`;//'https://exec.artificial.opalstacked.com/api/login2/';

const Keypad: React.FC<KeypadProps> = ({ onLogin }) => {
  const [input, setInput] = useState<string>('');

  const login2 = async (username: string, password: string) => {
    try {
      const response = await fetch(logInLink2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.error(data);

      if (response.ok && data.responseId === 0 && data.key) {

        // Assuming encodedKey is the Base64-encoded key from Django
        // const encodedKey = "eW91cl9iaW5hcnlfa2V5X3ZhbHVl";
        // "omC+6+lj3+aP3Y04oA9Zb+pEHg2sCfMuhWbd5Ag5ebk="
        const encodedKey = data.key;
        // Decode the Base64 string to bytes
        const decodedBytes = Uint8Array.from(atob(encodedKey), (c) => c.charCodeAt(0));
        // Convert the bytes to a CryptoKey
        window.crypto.subtle.importKey(
          'raw',
          decodedBytes,
          { name: 'AES-GCM' },
          false,
          ['encrypt', 'decrypt']
        ).then((cryptoKey) => {
          // Now you have a CryptoKey, you can use it for encryption or decryption with AES-GCM

          // Set the obtained key in the component state
          // setCryptoKey(data.key);

          //we need more complex ids from the database
          const userInfo: IUserInfo = {
            username: username,
            id: data.userId,
            key: cryptoKey
          }
          onLogin(userInfo);


          console.log('CryptoKey:', cryptoKey);
        });

      } 
    } catch (error) {
      console.error('An error occurred during login:', error);
      // Handle error, show message, etc.
    }
  };


  // Handle number button press
  const handleNumberPress = (num: number) => {
    if (input.length < 4) {
      const updatedInput = input + num.toString();
      setInput(updatedInput);

      // Attempt to login after 4 digits
      if (updatedInput.length === 4) {

        console.log("updatedInput",updatedInput);
        // onLogin(updatedInput);
        if (updatedInput==="9911") {
          login2('will','p@ssw0rd');
        } else if (updatedInput==="8855") {
          login2('nic','P@ssw0rd1234!@#$');
        } else {
          setInput("");
        }
      }
    }
  };

  // Render circles to visually represent the input
  const renderCircles = () => {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: index < input.length ? 'black' : 'lightgray',
            }}
          />
        ))}
      </div>
    );
  };

  return (
  
    <div className='login-form section-border' >
    <div style={{ textAlign: 'center', width: 200, margin: "auto" }}>
      {renderCircles()}
      <div style={{ marginTop: '20px' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
            <button key={num} style={buttonStyle} onClick={() => handleNumberPress(num)}>
              {num}
            </button>
          ))}
          <div></div> {/* Empty space for layout */}
          {/* <button style={buttonStyle} onClick={() => handleNumberPress(0)}>
            0
          </button> */}
        </div>
      </div>
    </div>
    </div>
  );
};

const buttonStyle: React.CSSProperties = {
  width: '60px',
  height: '60px',
  fontSize: '20px',
  borderRadius: '5px',
};

export default Keypad;
