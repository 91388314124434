import React, { useState } from 'react';
import axios from 'axios';
import { IDataForAPI, apiLink } from '../../radApi';
import { IChildPanelInfoProps } from '../../../types/main';
import { userInfo } from 'os';

interface IUploadFileProps extends IChildPanelInfoProps {
  triggerRefresh: () => void;
}

const UploadFile2: React.FC<IUploadFileProps> = ({ childPanelInfoState, setChildPanelInfoState, triggerRefresh }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [],
    status: 1,
    encryptData: childPanelInfoState.mainPanelInfo.system.encryptData
  }

  const [file, setFile] = useState<File | null>(null);
  const [type, setType] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [emailAddresses, setEmailAddresses] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [cost, setCost] = useState<string>('');
  const [project, setProject] = useState<string>('');
  const CHUNK_SIZE = 250 * 1024 * 1024; // 512 MB in bytes

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  // const handleSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (!file) return;

  //   const formData = new FormData();

  //   formData.append('user', (dataForAPI.userId as number).toString());
  //   formData.append('file', file);
  //   formData.append('file_type', type);
  //   formData.append('sendEmail', String(sendEmail));
  //   formData.append('emailAddresses', emailAddresses);
  //   formData.append('description', description);
  //   formData.append('cost', cost);
  //   formData.append('project', project);

  //   console.log('formData', formData);
  //   try {
  //     await axios.post(apiLink + '/executive/file_upload/', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
  //     //   onSuccess();
  //   } catch (error) {
  //     console.error('Error uploading file:', error);
  //   }
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) return;

    const formData = new FormData();

    // Ensure this is a valid user ID and not undefined/null
    formData.append('user', (dataForAPI.userId as number).toString());
    formData.append('file', file);
    formData.append('file_type', type);
    formData.append('sendEmail', String(sendEmail));
    formData.append('emailAddresses', JSON.stringify(emailAddresses));  // Serialize array
    formData.append('description', description);
    // formData.append('cost', cost);
    formData.append('cost', cost ? parseFloat(cost).toFixed(2) : '0.00');
    formData.append('project', project);

    try {
      await axios.post(apiLink + '/executive/file_upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      triggerRefresh();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // const uploadChunks = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (!file) return;

  //   const formData = new FormData();
  //   formData.append('user', (dataForAPI.userId as number).toString());
  //   formData.append('file_name', file.name.replace(/ /g, "_"));

  //   formData.append('file_type', type);
  //   formData.append('send_email', String(sendEmail));
  //   formData.append('email_addresses', JSON.stringify(emailAddresses));  // Serialize array
  //   formData.append('description', description);
  //   formData.append('cost', cost ? parseFloat(cost).toFixed(2) : '0.00');
  //   formData.append('project', project);

  //   const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

  //   formData.append('total_chunks', String(totalChunks));

  //   for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
  //     const start = chunkIndex * CHUNK_SIZE;
  //     const end = Math.min(start + CHUNK_SIZE, file.size);
  //     const fileChunk = file.slice(start, end); // Create chunk

  //     // const formData = new FormData();
  //     formData.append('chunk_number', String(chunkIndex));
  //     formData.append('chunk', fileChunk, `${file.name}.part${chunkIndex + 1}`);

  //     try {
  //       await axios.post(apiLink + '/executive/file_upload_chunk/', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       });
  //       triggerRefresh();
  //     } catch (error) {
  //       console.error('Error uploading file:', error);
  //     }
  //   }
  // };

  const uploadChunks = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!file) return;

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const formData = new FormData();  // Initialize a new FormData object for each chunk

      formData.append('user', (dataForAPI.userId as number).toString());
      formData.append('file_name', file.name.replace(/ /g, "_"));
      formData.append('file_type', type);
      formData.append('send_email', String(sendEmail));
      formData.append('email_addresses', JSON.stringify(emailAddresses));
      formData.append('description', description);
      formData.append('cost', cost ? parseFloat(cost).toFixed(2) : '0.00');
      formData.append('project', project);
      formData.append('total_chunks', String(totalChunks));
      formData.append('chunk_number', String(chunkIndex));

      const start = chunkIndex * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const fileChunk = file.slice(start, end);  // Create the file chunk
      formData.append('chunk', fileChunk, `${file.name}.part${chunkIndex + 1}`);

      try {
        await axios.post(apiLink + '/executive/file_upload_chunk/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        triggerRefresh();
      } catch (error) {
        console.error('Error uploading chunk:', error);
      }
    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <div className='spacer-10' />
      <div>
        <input
          type="text"
          placeholder="Project"
          value={project}
          defaultValue={"Wil"}
          onChange={(e) => setProject(e.target.value)}
        />
      </div>
      <div className='spacer-10' />
      <div>
        <input
          type="text"
          placeholder="File Type"
          value={type}
          defaultValue={"Image"}
          onChange={(e) => setType(e.target.value)}
        />
      </div>
      <div className='spacer-10' />
      <div>
        <input type="file" onChange={handleFileChange} />
      </div>
      <div className='spacer-10' />
      <div>
        <label>
          Send Email:
          <input
            type="checkbox"
            checked={sendEmail}
            onChange={(e) => setSendEmail(e.target.checked)}
          />
        </label>
      </div>
      <div className='spacer-10' />
      <div>
        <input
          type="text"
          placeholder="Email Addresses (comma separated)"
          value={emailAddresses}
          onChange={(e) => setEmailAddresses(e.target.value)}
        />
      </div>
      <div className='spacer-10' />
      <div>
        <input
          type="text"
          placeholder="Description"
          value={description}
          defaultValue={"Something important. :)"}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className='spacer-10' />
      <div>
        <input
          type="text"
          placeholder="Cost"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
        />
      </div>
      <div className='spacer-10' />
      <button type="submit">Upload</button> <button style={{ backgroundColor: childPanelInfoState.mainPanelInfo.styles.r2d2Blue }} onClick={uploadChunks}>Upload</button>
      <div className='spacer-10' />

    </form>
  );
};

export default UploadFile2;
