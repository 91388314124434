import React from 'react';
import { ChangeEvent, useEffect, useState } from "react"
import Alarms from "../../stage-items/alarms/Alarms";
import { Invoice } from "../../stage-items/invoice/Invoice";
import { Temperature } from "../../stage-items/temperature/Temperature";
import { MidiController } from "../../stage-items/midi-controller/MidiController";
import { Settings } from "../../stage-items/settings/Settings";
import { Notes } from "../../stage-items/notes/Notes";
import { Network } from "../../stage-items/network/Network";
import { Music } from "../../stage-items/music/Music";
import { MusicList } from "../../stage-items/music-list/MusicList";
import { Money } from "../../stage-items/money/Money";
import { Counters } from "../../stage-items/counters/Counters";
import { StageType } from "../../../enums/main";
import { IChildPanelInfoProps } from "../../../types/main";
import Upload from "../../stage-items/upload/Upload";
import Tuner from '../../stage-items/tuner/Tuner'; 
import Chat from '../../stage-items/chat/Chat';
// import { PostData } from "./invoice/not-used/PostData"
// import { Temperature } from "./temperature/Temperature"
// import { Invoice } from "./invoice/Invoice"
// import { MidiController } from "./midi-controller/MidiController"
// import Alarms from "../stage-items/alarms/Alarms"
// import { Network } from "./network/Network"
// import { Music } from "./music/Music"
// import { Money } from "./money/Money"
// import { Notes } from "./notes/Notes"
// import { Settings } from "./settings/Settings"
// import { OldSystem } from "./old-system/OldSystem"
// import StarField from "./star-field/StarField"
// import { MusicList } from "./music-list/MusicList"

export const PanelCenter: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [selectedStage, setSelectedStage] = useState<StageType>(StageType.Default); // Set default value

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // const selectedSound = event.target.value as StageType;
    // onSelect(selectedSound);

    // setPanelInfoState((prevState) => ({
    //   ...prevState, // Keep all other properties unchanged
    //   center: { stage: event.target.value as StageType }, // Update the specific property
    // }));
    if (childPanelInfoState.mainPanelInfo) {
      //setSelectedStage(event.target.value as StageType);
      const mainPanelInfo = {
        ...childPanelInfoState.mainPanelInfo,
        center: { ...childPanelInfoState.mainPanelInfo.center, stage: event.target.value as StageType },
      };
      setChildPanelInfoState((prevObject) => ({
        ...prevObject,
        mainPanelInfo: mainPanelInfo,
      }));
    }
    // setSelectedItem(event.target.value as StageType);
  };

  // Effect to call the function once when the component mounts
  useEffect(() => {
    // console.log('stage *this is interesting', childPanelInfoState.mainPanelInfo.center.stage);
  }, [childPanelInfoState.mainPanelInfo.center.stage]);

  // useEffect(() => {
  //   // setPanelInfoState((prevState) => ({
  //   //   ...prevState, // Keep all other properties unchanged
  //   //   center: { stage: StageType.Default }, // Update the specific property
  //   // }));
  //   if (childPanelInfoState.mainPanelInfo) {
  //     const mainPanelInfo = {
  //       ...childPanelInfoState.mainPanelInfo,
  //       center: { stage: StageType.Default },
  //     };
  //     setChildPanelInfoState((prevObject) => ({
  //       ...prevObject,
  //       mainPanelInfo: mainPanelInfo,
  //     }));
  //   }
  // }, []);

  return <div style={{width:'100%'}} >
    <select onChange={handleChange} value={childPanelInfoState.mainPanelInfo.center.stage}>
      {Object.values(StageType).map((stageOption) => (
        <option key={stageOption} value={stageOption}>
          {stageOption}
        </option>
      ))}
    </select>

    {/* <h3>{childPanelInfoState.mainPanelInfo.center.stage} === {StageType.MusicInfo}</h3> */}
    {/* {StageType.OldSystem} */}
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Default &&
      <div><div className="spacer-10"></div>Default
      {/* <StarField /> */}
      </div>
      
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Alarms &&
      <Alarms childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {/* <CountDownTimer /> */}
    {/* {panelInfoState.center.stage === StageType.PostData &&
      <PostData />
    } */}


    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Tuner &&
      <Tuner childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Invoice &&
      <Invoice childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Temperatures &&
      <Temperature childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.MidiController &&
      <MidiController childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Money &&
      <Money childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.MusicList &&  <>
      <MusicList childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /></>
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType[StageType.Music] && 
      <Music childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Network &&
      <Network childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Notes &&
      <Notes childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {/* {childPanelInfoState.mainPanelInfo.center.stage === StageType.Notes &&
      <div>bbbbbbbbbb<br />asdfasdfasdfasdf  <OldSystem childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /></div>
    } */}



    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Settings &&
      <Settings childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Counters &&
      <Counters childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }

    
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.FileUpload &&
      <Upload childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }
    {childPanelInfoState.mainPanelInfo.center.stage === StageType.Chat &&
      <Chat childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    }


  </div>;
};
{/* Rest of the Money section missing */ }


{/* <h4 className={`current-location-title ${selectedColor}`}>
            {centerMode}
            {usingIframe && (
                <span>
                    <a className="links-for-center" onClick={freezeCenter}>Freeze</a>
                    <a className="links-for-center" onClick={changeCenter}>Change</a>
                </span>
            )}
        </h4>
        {usingIframe && (
            <div>
                {refreshForcer ? (
                    <div className="width-100-percent">
                        {iframeSrcArrayCurrentLocation.map((item, i) => (
                            <div key={i} className={currentFrame === i + 1 ? '' : 'hide'}>
                                <iframe className="width-100-percent" src={item.src}></iframe>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="width-100-percent">
                        {iframeSrcArrayCurrentLocation.map((item, i) => (
                            <div key={i} className={currentFrame === i + 1 ? '' : 'hide'}>
                                <iframe className="width-100-percent" src={item.src}></iframe>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )}
        {!usingIframe && (
            <div>
                {centerMode === 'Invoice' && 'invoice'}
                {centerMode === 'Kid Words' && (
                    <table width="100%" border="1">
                        <tr>
                            <th>#</th>
                            <th>Word</th>
                            <th>Right</th>
                            <th>Wrong</th>
                            <th>Actions</th>
                        </tr>
                        {cloneWOD.map((item, i) => (
                            <tr key={i}>
                                <td>{i}</td>
                                <td>{item}</td>
                                <td></td>
                                <td></td>
                                <td><a className="white-link" href="">Delete</a></td>
                            </tr>
                        ))}
                    </table>
                )}
                {centerMode === 'Nicole' && (
                    <div>
                        <table border="1">
                            <tr>
                                <td>Add Client:</td>
                                <td>
                                    <input type="textbox" value={paidWhat} id="what" name="what" onChange={(e) => setPaidWhat(e.target.value)} />
                                </td>
                                <td><button>Add</button></td>
                            </tr>
                        </table>
                        <table border="1" width="100%">
                            <tr>
                                <th>Client</th>
                                <th>Rate</th>
                                <th>Actions</th>
                            </tr>
                            <tr>
                                <td>
                                    <select className="custom-select" name="option" id="option" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                        <option selected>Please Select...</option>
                                        {appService.listOfMoneyOptions.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="custom-select" name="option" id="option" value={selectedRate} onChange={(e) => setSelectedRate(e.target.value)}>
                                        <option selected>Please Select...</option>
                                        {appService.listOfMoneyOptions.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button>Start</button>
                                    <button>Stop</button>
                                </td>
                            </tr>
                        </table>
                        <table border="1" width="100%">
                            <tr>
                                <th>Client</th>
                                <th>Rate</th>
                                <th>Time</th>
                                <th>Total</th>
                                <th>Date</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                )}
                {centerMode === 'Money' && (
                    <div>
                        <br />
                        <div className="money-totals">
                            Nic: ${totalForNic.toFixed(2).toString()} Will: ${totalForWil.toFixed(2).toString()} - {totalDif}
                        </div>
                        <br />
                        
                    </div>
                )}
            </div>
        )} */}