import React, { useState, useEffect } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { IChildPanelInfoProps } from '../../../types/main';

interface Message {
  username: string;
  message: string;
}

const Chat: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [activeUsers, setActiveUsers] = useState<string[]>([]);
  const roomName = 'general'; // Example room

  useEffect(() => {
    const socket = new ReconnectingWebSocket(`ws://localhost:8000/ws/chat/${roomName}/`);

    socket.onmessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data);

      // Check if the message contains active users
      if (data.active_users) {
        setActiveUsers(data.active_users);
      }

      // Otherwise, handle chat messages
      if (data.message && data.username) {
        setMessages(prevMessages => [...prevMessages, { username: data.username, message: data.message }]);
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  const sendMessage = () => {
    const socket = new ReconnectingWebSocket(`ws://localhost:8000/ws/chat/${roomName}/`);
    socket.send(JSON.stringify({ message: input }));
    setInput('');
  };

  return (
    <div>
      <div>
        <h4>Available Users:</h4>
        <ul>
          {activeUsers.map((user, index) => (
            <li key={index}>{user}</li>
          ))}
        </ul>
      </div>
      <div>
        {messages.map((message, index) => (
          <div key={index}>
            <strong>{message.username}:</strong> {message.message}
          </div>
        ))}
      </div>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message..."
      />
      <div className='spacer-10' />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default Chat;