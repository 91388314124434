export enum StageType {
  Alarms = "Alarms",
  WorkWeek = "Work Week",
  Default = "Default",
  Invoice = "Invoice",
  MidiController = "Midi Controller",
  Money = "Money",
  Music = "Music",
  MusicList = "Music List",
  MusicInfo = "Music Info",
  Network = "Network",
  Notes = "Notes",
  // OldSystem = "Old System",
  // PostData = "Post Data",
  Settings = "Settings",
  Temperatures = "Temperatures",
  Words = "Words",
  Counters = "Counters",
  FileUpload = "File Upload",
  Tuner = "Tuner",
  Chat = "Chat"
}
  
  export enum DropDownType {
    Example1 = "Example 1",
    Example2 = "Example 2",
    Example3 = "Example 3",
  }